import jQuery from "jquery";
import '../../node_modules/slick-carousel/slick/slick.min.js';

(function($){
    $('.slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slider-nav',
        cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)',
        mobileFirst: true,
        useTransform: true,
    });
    $('.slider-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: true,
        centerMode: true,
        focusOnSelect: true
    });

    $('.slider-for').on('afterChange', function(event, slick, currentSlide) {
        console.log('currentSlide', currentSlide);
    });

    $('.slider-nav').on('click', '.slick-slide', function(event) {
        event.preventDefault();
        console.log('slick-index',$(this).data('slick-index'));
    });
})(jQuery);
